<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-card class="overflow-hidden" :disabled="loading">
      <v-tabs v-model="tab" grow show-arrows>
        <v-tab href="#health">Health </v-tab>
        <v-tab disabled href="#auto">Autos</v-tab>
        <template v-if="admins">
          <v-tab href="#logs">Logs</v-tab>
        <template v-if="showDetailsLogs">
          <v-tab href="#logs-details"
            >Log Details:
            {{
              detailsLogs ? detailsLogs.monthByAgent + ", " + detailsLogs.yearByAgent : ""
            }}</v-tab
          >
        </template>
        </template>
       

        <v-tab-item value="health">
          <v-divider></v-divider>
          <v-tabs align-with-title v-model="tabSelected">
            <v-tab v-for="(tab, i) in tabs" :key="i" :href="tab.href">
              <v-icon>{{ tab.icon }}</v-icon>
              {{ tab.title }}
            </v-tab>
            <v-tab-item value="payments1">
              <div class="fill-width px-2">
                <view-table-1
                  v-bind="filtersTotals"
                  :flexp="filterExpand"
                  @openFilter="openFilters($event)"
                />
              </div>
            </v-tab-item>
            <v-tab-item value="payments2">
              <div class="fill-width px-2">
                <view-table-2
                  v-bind="filtersMonths"
                  :flexp="filterExpand"
                  @openFilter="openFilters($event)"
                />
              </div>
            </v-tab-item>
          </v-tabs>
        </v-tab-item>
        <v-tab-item value="auto"></v-tab-item>
        <v-tab-item value="logs">
          <div class="fill-width px-2"><Logs @details="showDetais($event)" /></div>
        </v-tab-item>
        <template v-if="showDetailsLogs && detailsLogs">
          <v-tab-item value="logs-details">
            <div class="fill-width px-2">
              <LogsDetails :uuid="detailsLogs.uuid" />
            </div>
          </v-tab-item>
        </template>
      </v-tabs>
    </v-card>
    <template v-if="filterExpand">
      <v-navigation-drawer v-model="filterExpand" app v-cloak>
        <div class="d-flex pa-2">
          <span class="py-1">Filters</span><v-spacer></v-spacer
          ><v-btn text @click="closeFilters"><v-icon>mdi-close</v-icon></v-btn>
        </div>
        <v-divider></v-divider>
        <div class="d-flex flex-column">
          <template v-if="tabSelected == 'payments1'">
            <div class="col-sm-12">
              <label class="font-weight-medium" for="filterbyAgent">
                Year and Month by Import:
              </label>
              <div class="d-flex filterMonths">
                <div class="pr-1">
                  <v-select
                    outlined
                    dense
                    v-model="filtersTotals.yearByAgent"
                    :items="yearVisual"
                    label="Year"
                  ></v-select>
                </div>
                <div>
                  <v-select
                    outlined
                    dense
                    v-model="filtersTotals.monthByAgent"
                    :items="monthsVisual"
                    label="Month"
                  ></v-select>
                </div>
              </div>
              <template v-if="filtersTotals.yearByAgent || filtersTotals.monthByAgent">
                <div class="d-flex justify-end mt-1">
                  <v-btn color="error" text x-small @click="cleanDatesTotales">
                    <v-icon x-small>mdi-filter-remove</v-icon> Clean</v-btn
                  >
                </div>
              </template>
            </div>
            <v-divider></v-divider>

<div class="col-sm-12">
  <label class="font-weight-medium" for="filterMonth">
    Year and Months Paid:
  </label>
  <div class="filterMonths">
    <filter-months :fromT="true" ref="filtTotL" @dateSelect="setDatesTMonthpay($event)" />
  </div>

  <template v-if="filtersTotals.yandM.length != 0">
    <div class="d-flex justify-end">
      <v-btn color="error" text x-small @click="cleanDatesTbyAgentMonths">
        <v-icon x-small>mdi-filter-remove</v-icon> Clean</v-btn
      >
    </div>
  </template>
</div>
            <v-divider></v-divider>
            <template>
              <div class="col-sm-12">
                <v-autocomplete
                  v-model="filtersTotals.byagent"
                  label="by Agent"
                  :items="workers"
                  class="mx-1"
                  prepend-inner-icon="mdi-doctor"
                  item-text="fullname"
                  item-value="uuid"
                  :loading="loading"
                  hide-details
                  clearable
                  outlined
                  dense
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.fullname"></v-list-item-title>
                      <v-list-item-subtitle
                        v-if="data.item.ocupation"
                        class="text-capitalize"
                        v-html="data.item.ocupation.name"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </div>
              <v-divider></v-divider>
            </template>
            <div class="col-sm-12">
            <v-select
              v-model="filtersTotals.comId"
              label="by Company"
              :items="companies"
              prepend-inner-icon="mdi-briefcase-variant-outline"
              item-text="name"
              item-value="id"
              hide-details
              clearable
              outlined
              dense
              
            />
          </div>
          <v-divider></v-divider>
            <div class="col-sm-12">
              <v-select
                v-model="filtersTotals.ptoNoms"
                label="by Pay to"
                prepend-inner-icon="mdi-magnify"
                :items="paysTo"
                item-text="name"
                item-value="id"
                hide-details
                clearable
                multiple
                outlined
                dense
              ></v-select>
            </div>
            <v-divider></v-divider>
            <div class="col-sm-12">
              <v-select
                v-model="filtersTotals.pstatus"
                label="by Payment Status"
                prepend-inner-icon="mdi-magnify"
                :items="paymentStats"
                item-text="name"
                item-value="value"
                hide-details
                clearable
                outlined
                dense
              ></v-select>
            </div>
            <div class="col-sm-12">
            <v-row no-gutters>
              <div class="col-sm-12 d-flex">
                <span class="mt-1">Effective Date</span> <v-spacer></v-spacer>
                <template v-if="filtersTotals.effectiveDat.length!=0">
                  <v-btn small text color="error" @click="cleanFilters('totals')"> <v-icon>mdi-close</v-icon> clean</v-btn>
                </template>
                
              </div>
              <div class="col-sm-12 d-flex">
                <month-picker
                  ref="mtpTotals"
                  :inFilter="true"
                  :isMultiple="true"
                  @monthPick="filtersTotals.effectiveDat = $event"
                />
              </div>
              <!--  <div class="col-sm-12"><month-picker :inFilter="true"/></div> -->
            </v-row>
          </div>
          </template>
          <template v-else>
            <div class="col-sm-12">
              <label class="font-weight-medium" for="filterbyAgent">
                Year and Month by Import::
              </label>
              <div class="d-flex filterMonths">
                <div class="pr-1">
                  <v-select
                    outlined
                    dense
                    v-model="filtersMonths.yearByAgent"
                    :items="yearVisual"
                    label="Year"
                  ></v-select>
                </div>
                <div>
                  <v-select
                    outlined
                    dense
                    v-model="filtersMonths.monthByAgent"
                    :items="monthsVisual"
                    label="Month"
                  ></v-select>
                </div>
              </div>
              <template v-if="filtersMonths.yearByAgent || filtersMonths.monthByAgent">
                <div class="d-flex justify-end mt-1">
                  <v-btn color="error" text x-small @click="cleanDatesMonths">
                    <v-icon x-small>mdi-filter-remove</v-icon> Clean</v-btn
                  >
                </div>
              </template>
            </div>

            <v-divider></v-divider>

            <div class="col-sm-12">
              <label class="font-weight-medium" for="filterMonth">
                Year and Months Paid:
              </label>
              <div class="filterMonths">
                <filter-months  ref="filtMonth" @dateSelect="setDatesMonthpay($event)" />
              </div>

              <template v-if="filtersMonths.yandM.length != 0">
                <div class="d-flex justify-end">
                  <v-btn color="error" text x-small @click="cleanDatesbyAgentMonths">
                    <v-icon x-small>mdi-filter-remove</v-icon> Clean</v-btn
                  >
                </div>
              </template>
            </div>
            <v-divider></v-divider>
            <template v-if="admins">
              <div class="col-sm-12">
                <v-autocomplete
                  v-model="filtersMonths.byagent"
                  label="by Agent"
                  :items="workers"
                  class="mx-1"
                  prepend-inner-icon="mdi-doctor"
                  item-text="fullname"
                  item-value="uuid"
                  :loading="loading"
                  hide-details
                  clearable
                  outlined
                  dense
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.fullname"></v-list-item-title>
                      <v-list-item-subtitle
                        v-if="data.item.ocupation"
                        class="text-capitalize"
                        v-html="data.item.ocupation.name"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </div>
              <v-divider></v-divider>
            </template>
            <div class="col-sm-12">
            <v-select
              v-model="filtersMonths.comId"
              label="by Company"
              :items="companies"
              prepend-inner-icon="mdi-briefcase-variant-outline"
              item-text="name"
              item-value="id"
              hide-details
              clearable
              outlined
              dense
              
            />
          </div>
          <v-divider></v-divider>
          <template v-if="admins">
            <div class="col-sm-12">
              <v-select
                v-model="filtersMonths.ptoNoms"
                label="by Pay to"
                prepend-inner-icon="mdi-magnify"
                :items="paysTo"
                item-text="name"
                item-value="id"
                hide-details
                clearable
                multiple
                outlined
                dense
              ></v-select>
            </div>
            <v-divider></v-divider>

          </template>
           
            <div class="col-sm-12">
              <v-select
                v-model="filtersMonths.pstatus"
                label="by Payment Status"
                prepend-inner-icon="mdi-magnify"
                :items="paymentStats"
                item-text="name"
                item-value="value"
                hide-details
                clearable
                outlined
                dense
              ></v-select>
            </div>
            <v-divider></v-divider>
            <div class="col-sm-12">
              <v-select
                v-model="filtersMonths.activePaymentE"
                label="by Active Status"
                prepend-inner-icon="mdi-magnify"
                :items="activePaymentEnums"
                item-text="name"
                item-value="value"
                hide-details
                clearable
                outlined
                dense
              ></v-select>
            </div>
            <div class="col-sm-12">
            <v-row no-gutters>
              <div class="col-sm-12 d-flex">
                <span class="mt-1">Effective Date</span> <v-spacer></v-spacer>
                <template v-if="filtersMonths.effectiveDat.length!=0">
                  <v-btn small text color="error" @click="cleanFilters('months')"> <v-icon>mdi-close</v-icon> clean</v-btn>
                </template>
              </div>
              <div class="col-sm-12 d-flex">
                <month-picker
                  ref="mtpMonths"
                  :inFilter="true"
                  :isMultiple="true"
                  @monthPick="filtersMonths.effectiveDat = $event"
                />
              </div>
              
              <!--  <div class="col-sm-12"><month-picker :inFilter="true"/></div> -->
            </v-row>
          </div>
          </template></div
      ></v-navigation-drawer>
    </template>
  </div>
</template>
<script>
import ViewTable1 from "./components/ViewTable1.vue";
import ViewTable2 from "./components/ViewTable2.vue";
import Logs from "./components/Logs.vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { getAPI } from "@/api/axios-base";
import { notifyError } from "@/components/Notification";
import FilterMonths from "@/components/MaDatePicker/FilterMonths.vue";
import LogsDetails from "./components/LogsDetails.vue";
import MonthPicker from "@/components/MaDatePicker/MonthPicker.vue";
import moment from "moment";
export default {
  components: { ViewTable1, ViewTable2, Logs, FilterMonths, LogsDetails,MonthPicker },
  data() {
    return {
      admins:false,
      filterExpand: false,
      showDetailsLogs: false,
      detailsLogs: null,
      filtersTotals: {
        yearByAgent: null,
        monthByAgent: null,
        byagent: null,
        comId:null,
        yandM: [],
        effectiveDat:[],
        pstatus: null,
        ptoNoms: [],
      },
      filtersMonths: {
        byagent: null,
        pstatus: null,
        comId:null,
        ptoNoms: [],
        effectiveDat:[],
        yandM: [],
        yearByAgent: null,
        monthByAgent: null,
        activePaymentE: null,
      },
      paytoloading: false,
      paysTo: [],
      workers: [],
      paymentStats: [
        { name: "Pay", value: "PAY" },
        { name: "Reconciliation", value: "RECONCILIATION" },
        { name: "Member Problem", value: "MEMBER_PROBLEM" },
        { name: "Charge Back", value: "CHARGE_BACK" },
        { name: "End", value: "END" },
        { name: "Review", value: "REVIEW" },
      ],
      activePaymentEnums: [
        { name: "Pay", value: "V" },
        { name: "Don't Pay", value: "Y" },
        { name: "Inactive month", value: "O" },
        { name: "Charge back (PAY)", value: "X" },
        { name: "Charge back (DONT PAY)", value: "X1" },
      ],
      loading: false,
      tabSelected: "payments1",
      tab: null,
    
    };
  },
  computed: {
    ...mapState("crmSettingsModule", ["companies"]),
    ...mapGetters(["isSuper", "isAdmin","isSupervisor"]), 
    monthsVisual() {
      return [
        "JANUARY",
        "FEBRUARY",
        "MARCH",
        "APRIL",
        "MAY",
        "JUNE",
        "JULY",
        "AUGUST",
        "SEPTEMBER",
        "OCTOBER",
        "NOVEMBER",
        "DECEMBER",
      ];
    },

    yearVisual() {
      return Array.from(
        { length: 3 },
        (_, i) => Number(moment().format("YYYY")) - i
      );
    },


    tabs(){
      return this.admins? [
        {
          value: "payments1",
          title: "Total Payments",
          icon: "mdi-currency-usd",
          href: "#payments1",
        },
        {
          value: "payments2",
          title: "For Months",
          icon: "mdi-currency-usd",
          href: "#payments2",
        },
      ]:[
        {
          value: "payments2",
          title: "For Months",
          icon: "mdi-currency-usd",
          href: "#payments2",
        },
      ]}
  },
  watch: {
    tab: {
      handler(val) {
        if (val == "health" || val != "logs-details") {
          this.showDetailsLogs = false;
          this.detailsLogs = null;
        }

        this.closeFilters();
      },
    },
  },

  methods: {
    ...mapActions("crmSettingsModule", [
      "actListCompaniesNom",
      
    ]),
    ...mapMutations(["mutFilterDrawerExpand", "mutDrawerExpand"]),

    cleanFilters(type){
      if (type=='months') {
        this.filtersMonths.effectiveDat=[];
        this.$refs.mtpMonths.cancelMenu();
      }else{
        this.filtersTotals.effectiveDat=[];
        this.$refs.mtpTotals.cancelMenu();
      }
    },

    showDetais(value) {
      console.log(value);
      this.showDetailsLogs = value.show;
      this.detailsLogs = value.details;
      this.tab = "logs-details";
    },
    setDatesMonthpay(value) {
      this.filtersMonths.yandM = value;
    },
    setDatesTMonthpay(value) {
      this.filtersTotals.yandM = value;
    },
    openFilters(value) {
      this.filterExpand = value;
      this.mutFilterDrawerExpand(this.filterExpand);
      this.mutDrawerExpand(this.filterExpand);
    },

    closeFilters() {
      this.filterExpand = false;
      this.mutFilterDrawerExpand(this.filterExpand);
      this.mutDrawerExpand(this.filterExpand);
    },

    listPayto() {
      this.paytoloading = true;

      getAPI(`/pay-to-nom/list`)
        .then((res) => {
          this.paysTo = res.data.filter((r) => r.status == "ACTIVE");

          this.paytoloading = false;
        })
        .catch((error) => {
          this.paytoloading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    getEmployees() {
      getAPI("/users/listUsers").then((res) => {
        this.workers = res.data;
      });
    },

    cleanDatesTotales() {
      this.filtersTotals.monthByAgent = null;
      this.filtersTotals.yearByAgent = null;
    },
    cleanDatesMonths() {
      this.filtersMonths.monthByAgent = null;
      this.filtersMonths.yearByAgent = null;
    },
    cleanDatesbyAgentMonths() {
      this.filtersMonths.yandM = [];
      this.$refs.filtMonth.clearDate();
    },
    cleanDatesTbyAgentMonths() {
      this.filtersTotals.yandM = [];
      this.$refs.filtTotL.clearDate();
    },
  },
  async mounted() {
if (this.isAdmin||this.isSuper||this.isSupervisor) {
  this.admins=true
}else{
  this.admins=false
  this.tabSelected= "payments2"
}

    if (localStorage.getItem("logpayment")) {
      this.tab = "logs";
    }
    await this.listPayto();
    await this.getEmployees();
    await this.actListCompaniesNom();
  },
  destroyed() {
    localStorage.removeItem("logpayment");
  },
};
</script>
<style lang="scss" scoped>
.filterMonths {
  height: 40px !important;
}
</style>
